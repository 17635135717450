import { FC, Fragment, useState } from "react";
import { Button, Popup } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import Boschicon from "../BoschIcon/Boschicon";
import {
  selectAccount,
  selectUserRole,
} from "../../redux-toolkit/accountReducer";
import {
  addEventIdToCart,
  addRegisterdEmployee,
  RegisteredEmployee,
  selectCurrentRegisteredEmployee,
} from "../../redux-toolkit/shoppingCartReducer";
import AddToCartSuccessModal, {
  AddToCartEventModalInfo,
} from "./Modals/AddToCartSuccessModal";
import { addToCartAsync } from "../../redux-toolkit/shoppingCartUserReducer";
import {
  selectCountryCode,
  selectLanguage,
} from "../../redux-toolkit/authReducer";
import { CartEventItemAdded } from "../../models/CartItem.Model";
import { Constants } from "../../helpers/Constants";
import { useTranslation } from "react-i18next";
import InformationModal from "../InformationModal/InformationModal";
import { TrainingEventHelper } from "../../helpers/TrainingEventHelper";
import RDAlert from "../RDComponents/RDAlert";
import { TrainingEvent } from "../../models/TrainingEvent";
import { useAccountSettings } from "../../hooks/useAccountSettings";
import { CommonHelper } from "../../helpers/CommonHelper";

interface AddEventToCartComponentProps {
  eventInfo: any;
  isDisabled?: boolean;
  iconStyle?: any;
  buttonStyle?: any;
  addedToCartSuccess?: Function;
  isRegister?: boolean;
}

interface ParticipantAddedInfoProps {
  workshopId: number | undefined;
  participantId: number | undefined;
}

interface AddedEventProps {
  eventId: number;
  courseId: number;
}

const AddEventToCartComponent: FC<AddEventToCartComponentProps> = (props) => {
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const userInfoState = useSelector(selectAccount);
  const registeredEmp = useSelector(selectCurrentRegisteredEmployee);
  const [displayInfoModal, setDisplayInfoModal] = useState<boolean>(false);
  const userCountry = useSelector(selectCountryCode);
  const language = useSelector(selectLanguage);
  const userLanguage = useSelector(selectLanguage);
  const userRole = useSelector(selectUserRole);
  const [displayEventSuccessModel, setDisplayEventSuccessModel] =
    useState<AddToCartEventModalInfo | null>(null);
  const { t } = useTranslation();
  const [showInformationMessage, setShowInformationMessage] =
    useState<boolean>(false);
  const [informationMessage, setInformationMessage] = useState<string>("");
  const [popupTitle, setPopupTitle] = useState<string>("");
  const [isEnrolmentNotAcceptedError, setIsEnrolmentNotAcceptedError] =
    useState<boolean>(false);
  const getDateFormat = useAccountSettings();
  const isEventRegistable = (event: TrainingEvent) => {
    if (event) {
      let now = new Date();
      let lastEnrollmentDate = new Date(event.latestEnrollmentDate);
      return lastEnrollmentDate > now;
    }

    return false;
  };
  const handleRegisterClick = () => {
    let addedEventModel: AddedEventProps;
    if (props.eventInfo != null) {
      // Check if training event is in threshold days?
      if (!isEventRegistable(props.eventInfo)) {
        setIsEnrolmentNotAcceptedError(true);
      } else {
        let eventInfo =
          props.eventInfo.constructor.name === "Array"
            ? props.eventInfo[0]
            : props.eventInfo;
        addedEventModel = {
          eventId: eventInfo.eventId,
          courseId: eventInfo.courseId,
        };
        try {
          setDisplayEventSuccessModel({
            trainingName: TrainingEventHelper.getTrainingNameFromEvent(
              eventInfo,
              language,
              "-"
            ),
            /*  date: CommonHelper.DateFormatBySetting(
              eventInfo.startDate,
              getDateFormat()
            ), */
            date: CommonHelper.convertLocalizationDatetime(
              eventInfo?.startDate?.toString(),
              getDateFormat(),
              language
            ),
            days: eventInfo.trainingLength,
            location: eventInfo.venue?.city,
          });
        } catch (e) {
          // console.log((e as Error).message);
          setDisplayEventSuccessModel(null);
        }
        if (addedEventModel.eventId != null && addedEventModel.eventId >= 0) {
          addItemAndRedirect(addedEventModel);
        }
      }
    }
  };

  const setCloseAddToCartModalFunc = () => {
    setDisplayInfoModal(false);
    //USE FOR CLOSING PARENT'S POPUP
    if (props.addedToCartSuccess != null) {
      props.addedToCartSuccess();
    }
  };

  const addItemAndRedirect = (item: AddedEventProps) => {
    let partifipantInfo: ParticipantAddedInfoProps = {
      workshopId: registeredEmp.workshopId,
      participantId: registeredEmp.employeeId,
    };
    if (
      userRole?.toLowerCase() === Constants.USER_ROLE_INDIVIDUAL.toLowerCase()
    ) {
      partifipantInfo.participantId = userInfoState?.employeeId ?? 0;
      partifipantInfo.workshopId = userInfoState?.customerId ?? 0;
    }
    let cartEventItem = convertItemToEventCartItem(item, partifipantInfo);
    if (partifipantInfo?.participantId === 0) {
      setDisplayInfoModal(true);
      dispatchReduxToolkit(addEventIdToCart(item.eventId));
    } else {
      let addToCartRequest = dispatchReduxToolkit(
        addToCartAsync(cartEventItem)
      ).unwrap();
      addToCartRequest.then((res: any) => {
        if (res?.success) {
          ///IMPORTANT: THIS IS ONLY APPLY FOR ADDING ONE PARTICIPANT
          let cartId: number = 0;
          let addToCartErrorMes = "Error when adding to cart";
          if (
            res?.dataObject != null &&
            res.dataObject.constructor.name === "Array"
          ) {
            if (res.dataObject[0] != null) {
              cartId = Number(res.dataObject[0].id);
              addToCartErrorMes =
                res.dataObject[0].enrollResponse?.explananationLocalized ||
                res.dataObject[0].enrollResponse?.explananation;
            }
          } else {
            if (
              res?.dataObject == null &&
              res?.errorMessages != null &&
              res?.errorMessages.constructor.name === "Array"
            ) {
              addToCartErrorMes = res.errorMessages.join(", ");
            }
            cartId = Number(res?.dataObject);
          }
          if (cartId === 0) {
            handleDisplayAddtoCartMessage(addToCartErrorMes);
          } else {
            setDisplayInfoModal(true);
            let registeredEmployee: RegisteredEmployee = {
              employeeId: 0,
              workshopId: registeredEmp.workshopId,
            };
            dispatchReduxToolkit(addRegisterdEmployee(registeredEmployee));
          }
        } else {
          let errorMes = t("Error_Common");
          if (
            res?.errorMessages != null &&
            res.errorMessages.constructor.name === "Array"
          ) {
            errorMes = res.errorMessages.join(", ");
          }
          handleDisplayAddtoCartMessage(errorMes);
        }
      });
    }
  };

  const handleDisplayAddtoCartMessage = (errorMessage: string) => {
    let tempError = errorMessage.replace(/ /g, "");
    if (tempError.toLowerCase() === "alreadyincart") {
      errorMessage = t(
        "Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage"
      );
    }
    setInformationMessage(errorMessage);
    setPopupTitle(t("Addtocart_Modal_AddToCartFailTitle"));
    setShowInformationMessage(true);
  };

  const convertItemToEventCartItem = (
    item: AddedEventProps,
    participantInfo: ParticipantAddedInfoProps
  ): CartEventItemAdded => {
    let userCiamId = userInfoState?.ciamId ?? "";
    let userCustomerId = userInfoState?.customerId ?? 0;
    return {
      ciamId: userCiamId,
      customerId: Number(userCustomerId),
      country: userCountry,
      language: userLanguage,
      timeZoneId: "",
      cartItems: [
        {
          customerId:
            participantInfo.workshopId != null && participantInfo.workshopId > 0
              ? Number(participantInfo.workshopId)
              : 0,
          participantId:
            participantInfo.participantId != null &&
            participantInfo.participantId > 0
              ? Number(participantInfo.participantId)
              : 0,
          eventId: item.eventId,
          courseId: item.courseId,
          note: "",
        },
      ],
    };
  };

  return (
    <Fragment>
      <Popup
        size="tiny"
        content={t("Button_AddToCart_Tooltips")}
        trigger={
          <Button
            style={props.buttonStyle ?? null}
            disabled={
              // isEventRegistable(props.eventInfo) ||
              props.isDisabled
            }
            onClick={handleRegisterClick}
            icon={
              <Boschicon name="shopping-cart" style={props.iconStyle ?? null} />
            }
          />
        }
      />
      <AddToCartSuccessModal
        onDisplayModal={displayInfoModal}
        onCloseModalTrigger={setCloseAddToCartModalFunc}
        displayEventSuccessModel={displayEventSuccessModel}
      />

      <InformationModal
        type="error"
        isDisplayModal={showInformationMessage}
        informationMessage={informationMessage}
        popupTitle={t("Common_Error_Title")}
        setCloseModalTrigger={() => setShowInformationMessage(false)}
      />

      <RDAlert
        size="small"
        type="error"
        isOpen={isEnrolmentNotAcceptedError}
        title={t("EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle")}
        messages={[
          t("EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage"),
        ]}
        acceptButtonText={t("CommonButton_Close")}
        onAccept={() => {
          setIsEnrolmentNotAcceptedError(false);
        }}
      />
    </Fragment>
  );
};

export default AddEventToCartComponent;
