import { useSelector } from "react-redux";
import { selectDateFormatSetting } from "../redux-toolkit/accountReducer";
import { selectDateFormat, selectIsSignIn } from "../redux-toolkit/authReducer";
import { Constants } from "../helpers/Constants";

export const useAccountSettings = () => {
  const isLoggedIn = useSelector(selectIsSignIn);
  const authDateFormat = useSelector(selectDateFormat);
  const dateFormatSetting = useSelector(selectDateFormatSetting);

  const getDateFormat = () => {
    if (!isLoggedIn) {
      return authDateFormat !== null &&
        authDateFormat !== "" &&
        authDateFormat !== undefined
        ? authDateFormat
        : Constants.DEFAULT_DATE_FORMAT;
    }
    return dateFormatSetting !== null &&
      dateFormatSetting !== "" &&
      dateFormatSetting !== undefined
      ? dateFormatSetting
      : Constants.DEFAULT_DATE_FORMAT;
  };

  return getDateFormat;
};
