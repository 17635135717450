import {
  TimeInfo,
  TrainingMethod,
  TrainingPreRequisite,
  Venue,
} from "../models/TrainingEvent";
import i18next from "i18next";
import moment from "moment";
import { Constants } from "./Constants";
export class CommonHelper {
  static readonly getTrainingPreRequisitesDisplay = (
    trainingPreRequisites: TrainingPreRequisite[] | null | undefined
  ) => {
    if (!!trainingPreRequisites && trainingPreRequisites.length > 0)
      return trainingPreRequisites;
    return [];
  };

  static readonly getTrainingPreRequisitesDisplayCourseDetail = (
    trainingPreRequisites: TrainingPreRequisite[] | null | undefined
  ) => {
    if (!!trainingPreRequisites && trainingPreRequisites.length > 0)
      return trainingPreRequisites
        .map((x) => {
          return x.localizedName;
        })
        .join(", ");
    return i18next.t("Course_Detail_No_Prerequisites");
  };

  static readonly getTrainingMethodsDiplay = (
    trainingMethods: TrainingMethod[] | null | undefined
  ) => {
    if (trainingMethods && trainingMethods.length > 0)
      return trainingMethods
        .map((x) => {
          return x.localized;
        })
        .join(", ");
    return "-";
  };

  static readonly getPostCodeDisplaying = (
    countryCode: string | null,
    postcode: string,
    city: string
  ) => {
    if (!countryCode) return "-";
    if (
      countryCode.toLocaleUpperCase() === "AU" ||
      countryCode.toLocaleUpperCase() === "NZ"
    )
      return `${city} ${postcode}`;
    return `${postcode} ${city}`;
  };

  static readonly getAddressDisplaying = (
    venue: Venue | undefined | null,
    countryCode: string | null
  ) => {
    if (venue) {
      let postcodeD = CommonHelper.getPostCodeDisplaying(
        countryCode,
        venue?.postCode,
        venue?.city
      );
      return `${venue.address}, ${postcodeD}` || "-";
    }

    return "-";
  };

  static readonly getVenueDisplaying = (venue: Venue | undefined | null) => {
    if (venue) {
      let headline = venue?.headline || "";
      let name = venue?.name || "";
      return `${headline} ${name}` || "-";
    }

    return "-";
  };

  /* static readonly DateFormatBySetting = (
    dateString: string,
    format: string
  ) => {
    if (dateString) {
      if (format) {
        return moment(dateString).format(format);
      } else {
        return moment(dateString).format(Constants.DEFAULT_DATE_FORMAT);
      }
    }
    return "-";
  };
 */

  static readonly convertLocalizationDatetime = (
    inputDateString: string,
    inputFormat: string,
    locale: string
  ) => {
    if (inputDateString === "" || inputDateString === null) {
      return "-";
    }

    let format = inputFormat ?? Constants.DEFAULT_DATE_FORMAT;
    const dateWithFormat = new Date(inputDateString);

    if (dateWithFormat) {
      let month = new Intl.DateTimeFormat(locale, {
        month: format.includes("MMMM")
          ? "long"
          : format.includes("MMM")
          ? "short"
          : "2-digit",
      }).format(dateWithFormat);

      let day = new Intl.DateTimeFormat(locale, {
        day: format.includes("DDD") ? "numeric" : "2-digit",
      }).format(dateWithFormat);

      let year = new Intl.DateTimeFormat(locale, {
        year: format.includes("YYYY") ? "numeric" : "2-digit",
      }).format(dateWithFormat);

      let weekDay = new Intl.DateTimeFormat(locale, {
        weekday:
          format.includes("dddd") || format.includes("ddd") ? "long" : "short",
      }).format(dateWithFormat);

      let localizationDate = format
        .replace("dddd", weekDay)
        .replace("ddd", weekDay)
        .replace("dd", weekDay)
        .replace("YYYY", year)
        .replace("YYY", year)
        .replace("YY", year)
        .replace("MMMM", month)
        .replace("MMM", month)
        .replace("MM", month)
        .replace("DDDD", day)
        .replace("DDD", day)
        .replace("DD", day);

      return localizationDate;
    } else {
      return "-";
    }
  };

  static readonly LocalizationDateTimeByFormatSetting = (
    dateString: string,
    format: string,
    locale: string
  ) => {
    if (dateString) {
      if (format) {
        return moment(dateString).format(format);
      } else {
        return moment(dateString).format(Constants.DEFAULT_DATE_FORMAT);
      }
    }
    return "-";
  };

  static ConvertTimeLength(duration: TimeInfo | undefined | null) {
    if (duration) {
      if (duration.totalDays <= 1)
        if (duration.totalHours >= 1)
          return `${duration.totalHours} ${i18next.t("TableCell_Hours")}`;
        else if (duration.totalMinutes >= 1)
          return `${duration.totalMinutes} ${i18next.t("TableCell_Minutes")}`;
        else
          return `${duration.totalSeconds} ${i18next.t("TableCell_Seconds")}`;
      else
        return `${duration.totalDays}  ${i18next.t("TrainingCatalogue_Days")}`;
    }
    return "-";
  }

  static ConvertTimeLengthEx(duration: TimeInfo | undefined | null) {
    let durationText = "";
    if (duration) {
      let secondText = `${i18next.t("Duration_Second")}`;
      if (duration.seconds > 1) {
        secondText = `${i18next.t("Duration_Seconds")}`;
      }
      let seconds = `${duration.seconds} ${secondText}`;

      let minuteText = `${i18next.t("Duration_Minute")}`;
      if (duration.minutes > 1) {
        minuteText = `${i18next.t("TableCell_Minutes")}`;
      }
      let minutes = `${duration.minutes} ${minuteText}`;

      let hourText = `${i18next.t("Duration_Hour")}`;
      if (duration.hours > 1) {
        hourText = `${i18next.t("TableCell_Hours")}`;
      }
      let hours = `${duration.hours} ${hourText}`;

      let dayText = `${i18next.t("Duration_Day")}`;
      if (duration.days > 1) {
        dayText = `${i18next.t("TrainingCatalogue_Days")}`;
      }
      let days = `${duration.days} ${dayText}`;

      let isAppenedSeconds = durationText !== "";
      if (duration.seconds >= 1) {
        if (isAppenedSeconds) {
          durationText = `${seconds}, ${durationText}`;
        } else {
          durationText = `${seconds}`;
        }
      }

      isAppenedSeconds = durationText !== "";
      if (duration.minutes >= 1) {
        if (isAppenedSeconds) {
          durationText = `${minutes}, ${durationText}`;
        } else {
          durationText = `${minutes}`;
        }
      }

      isAppenedSeconds = durationText !== "";
      if (duration.hours >= 1) {
        if (isAppenedSeconds) {
          durationText = `${hours}, ${durationText}`;
        } else {
          durationText = `${hours}`;
        }
      }

      isAppenedSeconds = durationText !== "";
      if (duration.days >= 1) {
        if (isAppenedSeconds) {
          durationText = `${days}, ${durationText}`;
        } else {
          durationText = `${days}`;
        }
      }
    }

    return durationText !== "" ? durationText : "-";
  }

  static ConvertTrainingDuration(duration: TimeInfo | undefined | null) {
    let durationText = "";

    if (duration) {
      let secondText = `${i18next.t("Duration_Second")}`;
      if (duration.seconds > 1) {
        secondText = `${i18next.t("Duration_Seconds")}`;
      }
      let seconds = `${duration.seconds} ${secondText}`;

      let minuteText = `${i18next.t("Duration_Minute")}`;
      if (duration.minutes > 1) {
        minuteText = `${i18next.t("TableCell_Minutes")}`;
      }
      let minutes = `${duration.minutes} ${minuteText}`;

      let hourText = `${i18next.t("Duration_Hour")}`;
      if (duration.totalHours > 1) {
        hourText = `${i18next.t("TableCell_Hours")}`;
      }
      let hours = `${Math.floor(duration.totalHours)} ${hourText}`;

      let isAppenedSeconds = durationText !== "";
      if (duration.seconds >= 1) {
        if (isAppenedSeconds) {
          durationText = `${seconds}, ${durationText}`;
        } else {
          durationText = `${seconds}`;
        }
      }

      isAppenedSeconds = durationText !== "";
      if (duration.minutes >= 1) {
        if (isAppenedSeconds) {
          durationText = `${minutes}, ${durationText}`;
        } else {
          durationText = `${minutes}`;
        }
      }

      isAppenedSeconds = durationText !== "";
      if (duration.hours >= 1) {
        if (isAppenedSeconds) {
          durationText = `${hours}, ${durationText}`;
        } else {
          durationText = `${hours}`;
        }
      }
    }

    return durationText !== "" ? durationText : "-";
  }

  static TranslateDownloadCategory(catalogueId: number) {
    let categoryText = "";
    if (catalogueId) {
      switch (catalogueId) {
        case 1: //1	Catalogue
          categoryText = `${i18next.t("Download_Categories_Catalogue")}`;
          break;
        case 11: //Calendar
          categoryText = `${i18next.t("Download_Categories_Calendar")}`;
          break;
        case 16: //General
          categoryText = `${i18next.t("Download_Categories_General")}`;
          break;
        case 21: //Information
          categoryText = `${i18next.t("Download_Categories_Information")}`;
          break;
        case 26: //User guides
          categoryText = `${i18next.t("Download_Categories_Userguides")}`;
          break;
        default:
          categoryText = `${i18next.t("Download_Categories_General")}`;
          break;
      }
    }

    return categoryText;
  }

  public static readonly getUserProfileAttribute = (
    userAttribute: string,
    countryCodeLower: string,
    profile: any
  ) => {
    let ret;
    let phObject = profile[userAttribute + countryCodeLower];

    if (phObject) {
      const propertyNames = Object.keys(phObject);
      const propertyValues = Object.values(phObject);

      if (propertyNames?.length >= 1) {
        ret = (propertyValues[propertyNames?.length - 1] as boolean) ?? false;
      } else {
        ret = false;
      }
    } else {
      ret = false;
    }

    return ret;
  };
}
