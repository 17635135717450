import atFlag from "../assets/icon/flags/at.png";
import auFlag from "../assets/icon/flags/au.png";
import baFlag from "../assets/icon/flags/ba.png";
import beFlag from "../assets/icon/flags/be.png";
import bgFlag from "../assets/icon/flags/bg.png";
import brFlag from "../assets/icon/flags/br.png";
import chFlag from "../assets/icon/flags/ch.png";
import czFlag from "../assets/icon/flags/cz.png";
import deFlag from "../assets/icon/flags/de.png";
import dkFlag from "../assets/icon/flags/dk.png";
import eeFlag from "../assets/icon/flags/ee.png";
import esFlag from "../assets/icon/flags/es.png";
import fiFlag from "../assets/icon/flags/fi.png";
import frFlag from "../assets/icon/flags/fr.png";
import hrFlag from "../assets/icon/flags/hr.png";
import huFlag from "../assets/icon/flags/hu.png";
import idFlag from "../assets/icon/flags/id.png";
import ieFlag from "../assets/icon/flags/ie.png";
import inFlag from "../assets/icon/flags/in.png";
import itFlag from "../assets/icon/flags/it.png";
import jpFlag from "../assets/icon/flags/jp.png";
import krFlag from "../assets/icon/flags/kr.png";
import ltFlag from "../assets/icon/flags/lt.png";
import luFlag from "../assets/icon/flags/lu.png";
import lvFlag from "../assets/icon/flags/lv.png";
import myFlag from "../assets/icon/flags/my.png";
import nlFlag from "../assets/icon/flags/nl.png";
import noFlag from "../assets/icon/flags/no.png";
import nzFlag from "../assets/icon/flags/nz.png";
import plFlag from "../assets/icon/flags/pl.png";
import ptFlag from "../assets/icon/flags/pt.png";
import roFlag from "../assets/icon/flags/ro.png";
import ruFlag from "../assets/icon/flags/ru.png";
import sgFlag from "../assets/icon/flags/sg.png";
import slFlag from "../assets/icon/flags/sl.png";
import seFlag from "../assets/icon/flags/se.png";
import thFlag from "../assets/icon/flags/th.png";
import trFlag from "../assets/icon/flags/tr.png";
import ukFlag from "../assets/icon/flags/uk.png";
import usFlag from "../assets/icon/flags/us.png";
import zaFlag from "../assets/icon/flags/za.png";
import skFlag from "../assets/icon/flags/sk.png";

interface CountryAndLanguages {
  country: string;
  languages: string[];
}

interface HomePageLinks {
  countryName: string;
  countryCode: string;
  language: string;
  aboutUs: string;
  openOurPosition: string;
  getInTouch: string;
  automotiveAfterMarket: string;
}
export class Constants {
  public static stsAuthority = "https://p27.authz.bosch.com/auth/realms/sve1";
  public static clientid: "sve1-test-pkce";
  public static clientRoot = "http://localhost:3000/";
  public static clientScope = "openid profile email";
  public static apiRoot = "https://demo.identityserver.io/api/";

  public static USER_ROLE_INDIVIDUAL = "Individual";
  public static USER_ROLE_MANAGER = "Manager";
  public static USER_ROLE_WHOLESALER = "Wholesaler";

  public static PAYMENT_METHOD_DIRECT_DEBIT = "Direct Debit";
  public static PAYMENT_METHOD_VIA_WHOLESALER = "Via Wholesaler";
  public static PAYMENT_METHOD_CREDITS = "Credits";
  public static PAYMENT_METHOD_CARD = "Credit Card";
  public static PAYMENT_METHOD_DIRECT_ACCOUNT = "Direct Account";

  public static PAYMENT_METHOD_BOSCH_ACCOUNT_ID = 1;
  public static PAYMENT_METHOD_SERVICE_CREDIT_ID = 2;
  public static PAYMENT_METHOD_CREDIT_CARD_ID = 3;
  public static PAYMENT_METHOD_VIA_WHOLESALER_ID = 7;

  public static TRAININGCOURSECATALOGUE_VIEWINFOACTION = "info";
  public static TRAININGCOURSECATALOGUE_CARTACTION = "cart";
  public static TRAININGCOURSECATALOGUE_WAITLISTACTION = "waitlist";

  public static TRAININGEVENTSTATUSES_REGISTER = 10;
  public static TRAININGEVENTSTATUSES_INPROGRESS = 65;
  public static TRAININGEVENTSTATUSES_COMPLETED = 90;
  public static TRAININGEVENTSTATUSES_HISTORY = [20, 30, 40, 50, 90];
  public static TRAININGEVENTSTATUSES_WAITLIST = 60;
  public static TRAININGEVENTSTATUSES_WITHDRAWAL_PENDING = 100;

  public static COURSETABLE_ACTIVETAB_EVENTSTATUS = [10, 65];
  public static ACTIVE_EVENT_STATUS = [10, 65];
  public static COURSETABLE_SCHEDULEDTAB_COMPLETED_EVENTSTATUS = [90];
  public static COURSETABLE_ONLINETAB_COMPLETED_EVENTSTATUS = [90];
  public static COURSETABLE_CANCELLEDTAB_EVENTSTATUS = [20, 30, 40, 50, 80];

  public static COURSETABLE_SCHEDULEDTAB_TRAININGMETHODS = [1, 36, 11, 16, 21];
  public static COURSETABLE_ONLINETAB_TRAININGMETHODS = [6, 31, 41, 26];

  public static WEBCAST_LEARNING_METHOD_ID = 36;
  public static WEB_BASE_LEARNING_METHOD_ID = 6;
  public static VIDEO_LEARNING_METHOD_ID = 31;
  public static WEBINAR_LEARNING_METHOD_ID = 41;

  public static MYDASHBOARD_MYCOURSETAB = "my-courses";
  public static MYDASHBOARD_MYWAITLISTTAB = "my-waitlist";
  public static MYDASHBOARD_MYACOUNTDETAILSTAB = "my-account";
  public static MYDASHBOARD_MYACOUNTDETAILSEDITTAB = "edit-my-account";
  public static MYDASHBOARD_LATESTNEWSTAB = "latest-news";
  public static MYDASHBOARD_TRAININGPRGRAMTAB = "my-training-program";
  public static MYDASHBOARD_EMPLOYEES = "my-employees";
  public static MYDASHBOARD_SUPPLIER = "my-suppliers";
  public static MYDASHBOARD_TRANSACTION = "my-transaction";
  public static MYDASHBOARD_WORKSHOP = "my-workshops";
  public static MYDASHBOARD_MYWALLET = "my-wallet";
  public static MYDASHBOARD_EDITPROFILE = "edit-profile";

  public static CSIQUESTION_DEFAULTLIST_QUESTIONTYPE = 11;
  public static CSIQUESTION_TEXT_QUESTIONTYPE = 6;

  //public static netPriceCountries = ["DE", "AT", "CH", "ES", "PT"];

  public static pdfCourseCountries = ["AT", "CH", "DE"];

  public static DEFAULT_CURRICULA_STATUS_PDF_FILENAME = "Curricula_Status.pdf";

  public static NOTIFICATION_BAR_CONTACT_EMAIL_ADDRRESS_ES =
    "automotivecursosformacion@es.bosch.com";
  public static NOTIFICATION_BAR_CONTACT_EMAIL_ADDRRESS_DEFAULT =
    "automotive-training@bosch.com";

  public static DEFAULT_DATE_FORMAT = "DD/MM/YYYY";

  public static USER_ROLE_INDIVIDUAL_ID = 20;
  public static USER_ROLE_MANAGER_ID = 10;
  public static USER_ROLE_WHOLESALER_ID = 30;

  public static DISPLAYED_PARTICIPANTS_NUMBER = 3;

  public static noLangCountries = ["UK", "IE", "DE", "AT", "CH", "AU", "NZ"];

  public static NO_HOMEPAGE_WSA_COUNTRIES = ["UK", "IE"];
  public static RESTRICTED_GEO_COUNTRIES = ["RU", "SY", "IR", "KP", "BY"];

  public static readonly DEFAULT_MAP_ZOOM_LEVEL = 12;

  public static EMAIL_VALIDATION_REG = RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  public static NUMBER_ONLY_REG = RegExp(/^\+?\d*$/);
  public static SPECIAL_CHARACTERS_REG = RegExp(
    /[!$%^&*()+|~=`{}[:;<>?,/@#\]]/g
  );
  public static SPECIAL_CHARACTERS_FOR_PHONE_REG = RegExp(
    /[!$%^&*|~=`{}[:;<>?,/@#\]]/g
  );

  public static PREVIOUS_PAGE_LOCAL_STORAGE = "PREVIOUS_PAGE_LOCAL_STORAGE";

  public static DEFAULT_ICON_COURSE_CATEGORY_ID = 136;

  public static headerMap = [
    // ["/","Header_Anonymous"],
    ["/training-course-catalogue", "TrainingCatalogue_CourseCatalogue"],
    ["/event-calendar", "EventCalendar_EventCalendar"],
    ["/training-program", "TrainingProgram_TrainingProgram"],
    ["/training-centers", "Training_centers_header_bar_title"],
    ["/my-dashboard", "Dashboard_MyDashboard"],
    ["/data-protection-notice/0", "Footer_CorporateInformation"],
    ["/data-protection-notice/1", "Footer_LegalNotice"],
    ["/data-protection-notice/2", "Infor_PrivacyPolicy_Header"],
    ["/contacts", "ContactUs_Headline"],
    ["/my-dashboard/my-employees", "Dashboard_MyDashboard"],
    ["/my-dashboard/my-suppliers", "Dashboard_MyDashboard"],
    ["/my-dashboard/my-transaction", "Dashboard_MyDashboard"],
    ["/term-condition", "Footer_TermsAndConditions"],
    ["/shopping-cart", "CheckOutLayout_ShoppingCartHeader"],
    ["/training-course-detail", "Training_course_detail"],
    [
      "/training-centres/recommended-hotel",
      "Training_centers_header_bar_title",
    ],
    ["/exception", "Error_404"],
  ];

  public static homepageLinksByRegionsAndLanguage: HomePageLinks[] = [
    {
      countryName: "Denmark",
      countryCode: "DK",
      language: "da-DK",
      aboutUs: "https://www.bosch.dk/om-bosch/bosch-i-danmark/",
      openOurPosition: "https://www.bosch.dk/karrierer/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Denmark",
      countryCode: "DK",
      language: "en-DK",
      aboutUs: "https://www.bosch.dk/en/our-company/bosch-in-denmark/",
      openOurPosition: "https://www.bosch.dk/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Finland",
      countryCode: "FI",
      language: "fi",
      aboutUs: "https://www.bosch.fi/yrityksemme/bosch-suomessa/",
      openOurPosition: "https://www.bosch.fi/ura/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Finland",
      countryCode: "FI",
      language: "en-FI",
      aboutUs: "https://www.bosch.fi/en/our-company/bosch-in-finland/",
      openOurPosition: "https://www.bosch.fi/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Norway",
      countryCode: "NO",
      language: "nb-NO",
      aboutUs: "https://www.bosch.no/om-bosch/bosch-i-norge/",
      openOurPosition: "https://www.bosch.no/karriere/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Norway",
      countryCode: "NO",
      language: "en-NO",
      aboutUs: "https://www.bosch.no/en/our-company/bosch-in-norway/",
      openOurPosition: "https://www.bosch.no/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Sweden",
      countryCode: "SE",
      language: "sv-SE",
      aboutUs: "https://www.bosch.se/om-bosch/bosch-i-sverige/",
      openOurPosition: "https://www.bosch.se/jobba-hos-oss/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Sweden",
      countryCode: "SE",
      language: "en-SE",
      aboutUs: "https://www.bosch.se/en/our-company/bosch-in-sweden/",
      openOurPosition: "https://www.bosch.se/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Austria",
      countryCode: "AT",
      language: "de-AT",
      aboutUs: "https://www.bosch.at/unser-unternehmen/bosch-in-oesterreich/",
      openOurPosition: "https://www.bosch.at/karriere/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Austria",
      countryCode: "AT",
      language: "en-AT",
      aboutUs: "https://www.bosch.at/en/our-company/bosch-in-austria/",
      openOurPosition: "https://www.bosch.at/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Germany",
      countryCode: "DE",
      language: "de-DE",
      aboutUs: "https://www.bosch.de/unser-unternehmen/bosch-in-deutschland/",
      openOurPosition: "https://www.bosch.de/karriere/jobs/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Germany",
      countryCode: "DE",
      language: "en-DE",
      aboutUs: "https://www.bosch.de/en/our-company/bosch-in-germany/",
      openOurPosition: "https://www.bosch.de/en/career/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Japan",
      countryCode: "JP",
      language: "jp",
      aboutUs: "https://corporate.bosch.co.jp/our-company/bosch-in-japan/",
      openOurPosition: "https://corporate.bosch.co.jp/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Japan",
      countryCode: "JP",
      language: "en-JP",
      aboutUs: "https://corporate.bosch.co.jp/en/our-company/bosch-in-japan/",
      openOurPosition: "https://corporate.bosch.co.jp/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "New Zealand",
      countryCode: "NZ",
      language: "en-NZ",
      aboutUs: "https://www.bosch.co.nz/our-company/bosch-in-new-zealand/",
      openOurPosition: "https://www.bosch.co.nz/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "United Kingdom",
      countryCode: "UK",
      language: "en-GB",
      aboutUs: "https://www.bosch.co.uk/careers/",
      openOurPosition: "https://www.bosch.co.uk/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Spain",
      countryCode: "ES",
      language: "es-ES",
      aboutUs: "https://www.grupo-bosch.es/nuestra-empresa/bosch-en-espana/",
      openOurPosition: "https://www.grupo-bosch.es/trabaja-con-nosotros/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Spain",
      countryCode: "ES",
      language: "en-ES",
      aboutUs: "https://www.grupo-bosch.es/en/our-company/bosch-in-spain/",
      openOurPosition: "https://www.grupo-bosch.es/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Switzerland",
      countryCode: "CH",
      language: "de-CH",
      aboutUs: "https://www.bosch.ch/unser-unternehmen/bosch-in-der-schweiz/",
      openOurPosition: "https://www.bosch.ch/karriere/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Switzerland",
      countryCode: "CH",
      language: "en-CH",
      aboutUs: "https://www.bosch.ch/en/our-company/bosch-in-switzerland/",
      openOurPosition: "https://www.bosch.ch/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Korea",
      countryCode: "KR",
      language: "kr",
      aboutUs: "https://www.bosch.co.kr/our-company/bosch-in-korea/",
      openOurPosition: "https://www.bosch.co.kr/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Korea",
      countryCode: "KR",
      language: "en-KR",
      aboutUs: "https://www.bosch.co.kr/en/our-company/bosch-in-korea/",
      openOurPosition: "https://www.bosch.co.kr/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Portugal",
      countryCode: "PT",
      language: "pe-PT",
      aboutUs: "https://www.bosch.pt/a-nossa-empresa/bosch-em-portugal/",
      openOurPosition: "https://www.bosch.pt/carreiras/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Portugal",
      countryCode: "PT",
      language: "en-PT",
      aboutUs: "https://www.bosch.pt/en/our-company/bosch-in-portugal/",
      openOurPosition: "https://www.bosch.pt/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "United States",
      countryCode: "US",
      language: "en-US",
      aboutUs: "https://www.bosch.us/our-company/bosch-in-the-usa/",
      openOurPosition: "https://www.bosch.us/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Australia",
      countryCode: "AU",
      language: "en-AU",
      aboutUs: "https://www.bosch.com.au/our-company/bosch-in-australia/",
      openOurPosition: "https://www.bosch.com.au/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Estonia",
      countryCode: "EE",
      language: "et-EE",
      aboutUs: "https://www.bosch.ee/meie-ettevote/bosch-eestis/",
      openOurPosition: "https://www.bosch.ee/karjaeaer/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Estonia",
      countryCode: "EE",
      language: "en-EE",
      aboutUs: "'https://www.bosch.ee/en/our-company/bosch-in-estonia/",
      openOurPosition: "'https://www.bosch.ee/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Latvia",
      countryCode: "LV",
      language: "lv",
      aboutUs: "https://www.bosch.lv/musu-uznemums/bosch-latvija/",
      openOurPosition: "https://www.bosch.lv/karjeras-iespejas/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Latvia",
      countryCode: "LV",
      language: "en-LV",
      aboutUs: "https://www.bosch.lv/en/our-company/bosch-in-latvia/",
      openOurPosition: "https://www.bosch.lv/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Lithuania",
      countryCode: "LT",
      language: "lt",
      aboutUs: "https://www.bosch.lt/musu-imone/bosch-lietuvoje/",
      openOurPosition: "https://www.bosch.lt/karjera/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Lithuania",
      countryCode: "LT",
      language: "en-LT",
      aboutUs: "https://www.bosch.lt/en/our-company/bosch-in-lithuania/",
      openOurPosition: "https://www.bosch.lt/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Ireland",
      countryCode: "IE",
      language: "en-IE",
      aboutUs: "https://www.bosch.ie/our-company/bosch-in-ireland/",
      openOurPosition: "https://www.bosch.ie/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Bosnia",
      countryCode: "BA",
      language: "bs-BA",
      aboutUs: "https://www.bosch.ba/nasa-kompanija/",
      openOurPosition: "https://www.bosch.ba/karijere/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Bosnia",
      countryCode: "BA",
      language: "en-BA",
      aboutUs: "https://www.bosch.ba/en/our-company/",
      openOurPosition: "https://www.bosch.ba/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Bulgaria",
      countryCode: "BG",
      language: "bg",
      aboutUs: "https://www.bosch.bg/our-company/bosch-in-bulgaria/",
      openOurPosition: "https://www.bosch.bg/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Bulgaria",
      countryCode: "BG",
      language: "en-BG",
      aboutUs: "https://www.bosch.bg/en/our-company/bosch-in-bulgaria/",
      openOurPosition: "https://www.bosch.bg/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Croatia",
      countryCode: "HR",
      language: "hr",
      aboutUs: "https://www.bosch.hr/nasa-tvrtka/bosch-u-hrvatskoj/",
      openOurPosition: "https://www.bosch.hr/karijere/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Croatia",
      countryCode: "HR",
      language: "en-HR",
      aboutUs: "https://www.bosch.hr/en/our-company/bosch-in-croatia/",
      openOurPosition: "https://www.bosch.hr/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Romania",
      countryCode: "RO",
      language: "ro",
      aboutUs: "https://www.bosch.ro/compania-noastra/bosch-in-romania/",
      openOurPosition: "https://www.bosch.ro/cariere/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Romania",
      countryCode: "RO",
      language: "en-RO",
      aboutUs: "https://www.bosch.ro/en/our-company/bosch-in-romania/",
      openOurPosition: "https://www.bosch.ro/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Slovenia",
      countryCode: "SL",
      language: "sl",
      aboutUs: "https://www.bosch.si/nase-podjetje/bosch-v-sloveniji/",
      openOurPosition: "https://www.bosch.si/kariere/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Slovenia",
      countryCode: "SL",
      language: "en-SL",
      aboutUs: "https://www.bosch.si/en/our-company/bosch-in-slovenia/",
      openOurPosition: "https://www.bosch.si/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Belgium",
      countryCode: "BE",
      language: "fr-BE",
      aboutUs: "https://www.bosch.be/fr/notre-entreprise/bosch-en-belgique/",
      openOurPosition: "https://www.bosch.be/fr/carrieres/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Belgium",
      countryCode: "BE",
      language: "nl-BE",
      aboutUs: "https://www.bosch.be/nl/ons-bedrijf/bosch-in-belgie/",
      openOurPosition: "https://www.bosch.be/nl/carriere/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "",
    },
    {
      countryName: "Belgium",
      countryCode: "BE",
      language: "en-BE",
      aboutUs: "https://www.bosch.be/our-company/bosch-in-belgium/",
      openOurPosition: "https://www.bosch.be/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "France",
      countryCode: "FR",
      language: "fr-FR",
      aboutUs: "https://www.bosch.fr/notre-entreprise/bosch-en-france/",
      openOurPosition: "https://www.bosch.fr/carrieres/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "France",
      countryCode: "FR",
      language: "en-FR",
      aboutUs: "https://www.bosch.fr/en/our-company/bosch-in-france/",
      openOurPosition: "https://www.bosch.fr/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Luxembourg",
      countryCode: "LU",
      language: "fr-LU",
      aboutUs: "https://www.bosch.be/fr/notre-entreprise/bosch-en-belgique/",
      openOurPosition: "https://www.bosch.be/fr/carrieres/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Luxembourg",
      countryCode: "LU",
      language: "en-LU",
      aboutUs: "https://www.bosch.be/our-company/bosch-in-belgium/",
      openOurPosition: "https://www.bosch.be/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Netherlands",
      countryCode: "NL",
      language: "nl-NL",
      aboutUs: "https://www.bosch.nl/ons-bedrijf/bosch-in-nederland/",
      openOurPosition: "https://www.bosch.nl/carriere/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Netherlands",
      countryCode: "NL",
      language: "en-NL",
      aboutUs: "https://www.bosch.nl/en/our-company/bosch-in-the-netherlands/",
      openOurPosition: "https://www.bosch.nl/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Malaysia",
      countryCode: "MY",
      language: "ms-MY",
      aboutUs: "https://www.bosch.com.my/our-company/bosch-in-malaysia/",
      openOurPosition: "https://www.bosch.com.my/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Malaysia",
      countryCode: "MY",
      language: "en-MY",
      aboutUs: "https://www.bosch.com.my/our-company/bosch-in-malaysia/",
      openOurPosition: "https://www.bosch.com.my/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Singapore",
      countryCode: "SG",
      language: "en-SG",
      aboutUs: "https://www.bosch.com.sg/our-company/bosch-in-singapore/",
      openOurPosition: "https://www.bosch.com.sg/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Thailand",
      countryCode: "TH",
      language: "th",
      aboutUs: "https://www.bosch.co.th/our-company/bosch-in-thailand/",
      openOurPosition: "https://www.bosch.co.th/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Thailand",
      countryCode: "TH",
      language: "en-TH",
      aboutUs: "https://www.bosch.co.th/en/our-company/bosch-in-thailand/",
      openOurPosition: "https://www.bosch.co.th/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Indonesia",
      countryCode: "ID",
      language: "id",
      aboutUs: "https://www.bosch.co.id/id/perusahaan-kami/bosch-di-indonesia/",
      openOurPosition: "https://www.bosch.co.id/id/karier/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Indonesia",
      countryCode: "ID",
      language: "en-ID",
      aboutUs: "https://www.bosch.co.id/our-company/bosch-in-indonesia/",
      openOurPosition: "https://www.bosch.co.id/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Italy",
      countryCode: "IT",
      language: "it",
      aboutUs: "https://www.bosch.it/la-nostra-azienda/bosch-in-italia/",
      openOurPosition: "https://www.bosch.it/lavora-con-noi/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
    {
      countryName: "Italy",
      countryCode: "IT",
      language: "en-IT",
      aboutUs: "https://www.bosch.it/en/our-company/bosch-in-italy/",
      openOurPosition: "https://www.bosch.it/en/careers/",
      getInTouch: "/contacts",
      automotiveAfterMarket: "https://www.boschaftermarket.com/xc/en",
    },
  ];

  public static IconCategoryMap = [
    {
      id: 1,
      categoryInfo: "DiagnosticsandTestEquipment",
      color: "rgb(226, 0, 21)",
      imageUrl: "Driven_by_Allrounders_300x200px.jpg",
      iconUrl: "DiagnosticsAndTestEquipment.jpg",
      categoryText: "Diagnostics and Test Equipment",
    },

    {
      id: 11,
      categoryInfo: "AutomotiveTechnologyFundamentals",
      color: "rgb(185, 2, 118)",
      imageUrl: "Allrounders_horizontal_300x200px.jpg",
      iconUrl: "AutomotiveFundamental.jpg",
      categoryText: "Automotive Technology Fundamentals",
    },
    {
      id: 21,
      categoryInfo: "Gasoline",
      color: "rgb(80, 36, 127)",
      imageUrl: "Motorhaube_300x200.jpg",
      iconUrl: "Gasoline.jpg",
      categoryText: "Gasoline",
    },
    {
      id: 31,
      categoryInfo: "Diesel",
      color: "rgb(167, 150, 191)",
      imageUrl: "Praxistraining_Motorhaube_300x200px.jpg",
      iconUrl: "Diesel.jpg",
      categoryText: "Diesel",
    },
    {
      id: 41,
      categoryInfo: "AlternativeDrives",
      color: "rgb(0, 86, 145)",
      imageUrl: "AR_training_300x200px.jpg",
      iconUrl: "AlternativeDrive.jpg",
      categoryText: "Alternative Drives",
    },
    {
      id: 51,
      categoryInfo: "ElectricalandElectronics",
      color: "rgb(127, 170, 200)",
      imageUrl: "Technician_300x200px.jpg",
      iconUrl: "ElectricElectronics.jpg",
      categoryText: "Electrical and Electronics",
    },
    {
      id: 61,
      categoryInfo: "BodyandComfort",
      color: "rgb(0, 142, 207)",
      imageUrl: "FSA_Diagnose_300x200px.jpg",
      iconUrl: "BodyComfort.jpg",
      categoryText: "Body and Comfort",
    },
    {
      id: 71,
      categoryInfo: "ChassisandBraking",
      color: "rgb(133, 198, 231)",
      imageUrl: "Bremsscheibenwechsel_300x200px.jpg",
      iconUrl: "ChassisAndBraking.jpg",
      categoryText: "Chassis and Braking",
    },
    {
      id: 81,
      categoryInfo: "TransmissionandDrivetrain",
      color: "rgb(0, 168, 176)",
      imageUrl: "Praxistraining_Hebe_300x200px.jpg",
      iconUrl: "TransmissionDrivetrain.jpg",
      categoryText: "Transmission and Drivetrain",
    },
    {
      id: 91,
      categoryInfo: "CommercialVehicle",
      color: "rgb(128, 212, 215)",
      imageUrl: "KTS_Truck_300x200px.jpg",
      iconUrl: "CommercialVehicle.jpg",
      categoryText: "Commercial Vehicle",
    },
    {
      id: 101,
      categoryInfo: "OnlineTrainingCourses",
      color: "rgb(120, 190, 32)",
      imageUrl: "Diagnosis_Simulation_300x200px.jpg",
      iconUrl: "OnlineTraining.jpg",
      categoryText: "Online Training Courses",
    },
    {
      id: 111,
      categoryInfo: "BusinessSkills",
      color: "rgb(0, 98, 73)",
      imageUrl: "Driven_by_Teamspirit_300x200px.jpg",
      iconUrl: "BusinessSkills.jpg",
      categoryText: "Business Skills",
    },
    {
      id: 121,
      categoryInfo: "Accreditations",
      color: "rgb(128, 177, 164)",
      imageUrl: "Depositphotos_300x200px.jpg",
      iconUrl: "Accreditations.jpg",
      categoryText: "Accreditations",
    },
    {
      id: 131,
      categoryInfo: "TrainingCourseProgrammes",
      color: "rgb(82, 95, 107)",
      imageUrl: "Uns_bewegt_Wissensdurst_300x200px.jpg",
      iconUrl: "TrainingProgram.jpg",
      categoryText: "Training Course Programmes",
    },
    {
      id: 136,
      categoryInfo: "Others",
      color: "rgb(240, 143, 146)",
      imageUrl: "Start_Stopp_anlernen_300x200px.jpg",
      iconUrl: "Other.jpg",
      categoryText: "Others",
    },
    {
      id: 141,
      categoryInfo: "EntryLevel",
      color: "rgb(188, 223, 143)",
      imageUrl: "EntryLevel_300x200px.jpg",
      iconUrl: "Entry Level.jpg",
      categoryText: "Entry Level",
    },
  ];

  public static TrainingMethodMap = [
    {
      id: 1,
      methodName: "ClassroomBased",
      methodText: "Classroom-Based",
    },
    {
      id: 6,
      methodName: "WebBasedTraining",
      methodText: "Web Based Training",
    },
    {
      id: 11,
      methodName: "Handson",
      methodText: "Hands-on",
    },
    {
      id: 16,
      methodName: "Interactive",
      methodText: "Interactive",
    },
    {
      id: 21,
      methodName: "Onthejob",
      methodText: "On-the-job",
    },
    {
      id: 26,
      methodName: "Simulation",
      methodText: "Simulation",
    },
    {
      id: 31,
      methodName: "Video",
      methodText: "Video",
    },
    {
      id: 36,
      methodName: "Webcast",
      methodText: "Webcast",
    },
    {
      id: 41,
      methodName: "Webinar",
      methodText: "Webinar",
    },
  ];

  public static EnrollmentStatusMap = [
    {
      id: 10,
      statusName: "Registered",
      statusText: "Registered",
    },
    {
      id: 20,
      statusName: "Withdrawn",
      statusText: "Withdrawn",
    },
    {
      id: 30,
      statusName: "WithdrawnSick",
      statusText: "Withdrawn - Sick",
    },
    {
      id: 40,
      statusName: "NoShow",
      statusText: "No Show",
    },
    {
      id: 50,
      statusName: "Cancelled",
      statusText: "Cancelled",
    },
    {
      id: 60,
      statusName: "WaitListed",
      statusText: "Wait Listed",
    },
    {
      id: 65,
      statusName: "InProgress",
      statusText: "In Progress",
    },
    {
      id: 70,
      statusName: "Pending",
      statusText: "Pending",
    },
    {
      id: 80,
      statusName: "Expired",
      statusText: "Expired",
    },
    {
      id: 90,
      statusName: "Completed",
      statusText: "Completed",
    },
  ];

  public static flagImages = [
    {
      country: "AT",
      flag: atFlag,
    },
    {
      country: "AU",
      flag: auFlag,
    },
    {
      country: "BA",
      flag: baFlag,
    },
    {
      country: "BE",
      flag: beFlag,
    },
    {
      country: "BG",
      flag: bgFlag,
    },
    {
      country: "BR",
      flag: brFlag,
    },
    {
      country: "CH",
      flag: chFlag,
    },
    {
      country: "CZ",
      flag: czFlag,
    },
    {
      country: "DE",
      flag: deFlag,
    },
    {
      country: "DK",
      flag: dkFlag,
    },
    {
      country: "EE",
      flag: eeFlag,
    },
    {
      country: "ES",
      flag: esFlag,
    },
    {
      country: "FI",
      flag: fiFlag,
    },
    {
      country: "FR",
      flag: frFlag,
    },
    {
      country: "HR",
      flag: hrFlag,
    },
    {
      country: "HU",
      flag: huFlag,
    },
    {
      country: "ID",
      flag: idFlag,
    },
    {
      country: "IE",
      flag: ieFlag,
    },
    {
      country: "IN",
      flag: inFlag,
    },
    {
      country: "IT",
      flag: itFlag,
    },
    {
      country: "JP",
      flag: jpFlag,
    },
    {
      country: "KR",
      flag: krFlag,
    },
    {
      country: "LT",
      flag: ltFlag,
    },
    {
      country: "LU",
      flag: luFlag,
    },
    {
      country: "LV",
      flag: lvFlag,
    },
    {
      country: "MY",
      flag: myFlag,
    },
    {
      country: "NL",
      flag: nlFlag,
    },
    {
      country: "NO",
      flag: noFlag,
    },
    {
      country: "NZ",
      flag: nzFlag,
    },
    {
      country: "PL",
      flag: plFlag,
    },
    {
      country: "PT",
      flag: ptFlag,
    },
    {
      country: "RO",
      flag: roFlag,
    },
    {
      country: "RU",
      flag: ruFlag,
    },
    {
      country: "SG",
      flag: sgFlag,
    },
    {
      country: "SL",
      flag: slFlag,
    },
    {
      country: "SE",
      flag: seFlag,
    },
    {
      country: "TH",
      flag: thFlag,
    },
    {
      country: "TR",
      flag: trFlag,
    },
    {
      country: "UK",
      flag: ukFlag,
    },
    {
      country: "US",
      flag: usFlag,
    },
    {
      country: "ZA",
      flag: zaFlag,
    },
    {
      country: "SK",
      flag: skFlag,
    },
  ];

  public static batsFileIcon = [
    {
      iconName: "bosch-ic-document-pdf",
      extentions: [".pdf"],
    },
    {
      iconName: "bosch-ic-document-x",
      extentions: [".xls"],
    },
    {
      iconName: "bosch-ic-document-ppt",
      extentions: [".ppt"],
    },
    {
      iconName: "bosch-ic-document-doc",
      extentions: [".doc"],
    },
    {
      iconName: "bosch-ic-imagery",
      extentions: [".jpeg", ".gif", ".png", ".jpg"],
    },
  ];

  /**
   * Statistic report file
   */
  public static readonly REPORT_FILE_NAME_DELIMITER = "_";
  public static readonly REPORT_FILE_NAME_POST_FIX = "";
  public static readonly REPORT_FILE_CELL_MIN_WIDTH = 12;
  public static readonly REPORT_FILE_TRIPLE_THAN_DEFAULT_WIDTH_COLS = [
    "comment",
    "solution",
  ];
}

export type FILEDOWNLOAD_OPTIONS = "OPEN" | "SAVE" | "CANCEL" | "";
